<template>
  <div id="app"  :style="{width:screenWidth+'px'}">
    <router-view></router-view>
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";
export default {
  name: "app",
  components: {
    HelloWorld,
  },
  data(){
	  return{
		  screenWidth: document.body.clientWidth-2
	  }
  },
  mounted() {
	  //打包
	  // console.log(11111)
	localStorage.setItem('languageSet','en')
	// console.log(localStorage.getItem('languageSet'))
  },
  methods:{
	  async accountInformationObj() {
	  	let _this = this
	  	try {
	  		let res = await this.$axios.post('match/upLeftDays');
	  		if (res.code == 1) {
				localStorage.setItem('dayNum',res.data.daynum);
	  		}
	  	} catch (error) {
	  		console.log(error);
	  	}
	  
	  },
  }
};
</script>

<style lang="less">
	.el-popover{
		outline-style: none;
	}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* line-height: 150%; */
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 18px;
  min-width: 1700px!important;
}
.sign_app_text{
	color: #FF0000;
}
.icon_image {
  display: flex;
  align-content: center;
  justify-items: center;
  overflow: hidden;
  > img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
.floating_hovr:hover{
	border-color: #932224 !important;
	color: #932224 !important;
	transition: all .8s linear 0s;
	cursor: pointer;  /*鼠标悬停变小手*/
}
.floatingBoder_hovr:hover{
	transition: all .8s linear 0s;
	border-color: #932224 !important;
	cursor: pointer;  /*鼠标悬停变小手*/
}
.flex_disp{
		display: flex!important;
		align-items: center;
	}
	.flex_just_disp{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
.text_2_size {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.cursorS{
	cursor: pointer;  /*鼠标悬停变小手*/
}
.text_3_size {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.text_1_size {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import VueI18n from 'vue-i18n';
import VueLazyload from 'vue-lazyload' // 图片延迟加载
import './api/rem';
Vue.use(VueI18n)
import $ from 'jquery';
// Vue.prototype.$=$
import './style/animation.css';
import $axios from'./api/axios.js';
import util from'./api/util.js';
Vue.prototype.$util =util;
Vue.prototype.$axios = $axios;
Vue.prototype.$number = 0;
import VueClipboard from 'vue-clipboard2' 

Vue.use(VueClipboard)
const i18n=new VueI18n({
    locale:localStorage.getItem('languageSet')||'en',   //从localStorage里获取用户中英文选择，没有则默认中文
    messages:{
        'zh':require('./api/zh'),
        'en':require('./api/en')
    }
})

Vue.prototype.getViewportSize = function(){
  return {
    width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
    height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
  };
};
class Custom{
	constructor(placeholder="还未设置内容",type="string",maxlength=11,minlength=1,showPass=false,clearable=false,disabled=false,readonly=false,autofocus=false,validate=false) {
		this.placeholder=placeholder;
		this.type=type;
		// 最大输入长度
		this.maxlength=maxlength;
		// 最小输入长度
		this.minlength=minlength;
		// 是否显示切换密码图标
		this.showPass=showPass;
		// clearable
		this.clearable=clearable;
		// 禁用
		this.disabled=disabled;
		// 是否只读
		this.readonly=readonly;
		// 自动获取焦点
		this.autofocus=autofocus;
		// 输入时是否触发表单的校验
		this.validate=validate;
	}
}
Vue.prototype.$Custom=Custom;
Vue.config.productionTip = false;
Vue.use(VueLazyload, {
  loading: './assets/logo.png',
  attempt: 1,
})
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
